import React, { useEffect } from 'react';
import { createPortal } from 'react-dom';
import clsx from 'clsx';

import { styled } from 'styles';
import Button from '../Button';
import Icon from '../Icon';

type Position = 'left' | 'right';

export interface SideMenuProps extends React.HTMLAttributes<HTMLDivElement> {
  /**
   * Mount node. Default is "#modal-root"
   */
  nodeSelector?: string;
  /**
   * Open state.
   */
  open: boolean;
  /**
   * Menu positon on screen.
   */
  position?: Position;
  /**
   * Hide "X" button (menu still can be closed by clicking on overlay).
   */
  hideCloseButton?: boolean;
  /**
   * Function called when "X" button or overlay is clicked.
   */
  onClose?(): any;
  /**
   * Function called when "X" button or overlay is clicked.
   */
  title?: string;
  className?: string;
}

const SideMenu: React.FC<SideMenuProps> = ({
  nodeSelector = '#modal-root',
  open,
  position = 'right',
  hideCloseButton,
  onClose,
  className,
  title,
  children,
  ...props
}) => {
  let node = document.querySelector(nodeSelector);
  if (!node) {
    node = document.createElement('div');
    node.setAttribute('id', nodeSelector);
    document.body.appendChild(node);
  }

  const handleKeyDown = (e: KeyboardEvent) => {
    if (e.key === 'Escape') onClose?.();
  };

  useEffect(() => {
    if (open) document.addEventListener('keydown', handleKeyDown);
    else document.removeEventListener('keydown', handleKeyDown);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open]);

  if (!open) return null;
  return createPortal(
    <StyledOverlay
      className={clsx('side-menu-overlay', className)}
      onMouseDown={onClose}
      position={position}>
      <StyledSideMenu
        className={clsx('side-menu', position)}
        onMouseDown={e => e.stopPropagation()}
        {...props}>
        {title && <h2 className="title">{title}</h2>}
        {!hideCloseButton && (
          <Button className="close-btn" onClick={onClose}>
            <Icon name="mini-x" />
          </Button>
        )}
        {children}
      </StyledSideMenu>
    </StyledOverlay>,
    node
  );
};

export default React.memo(SideMenu);

type StyledOverlayProps = {
  position: Position;
};

const StyledOverlay = styled.div<StyledOverlayProps>`
  position: fixed;
  display: flex;
  justify-content: ${(props: StyledOverlayProps) =>
    props.position === 'left' ? 'flex-start' : 'flex-end'};
  background: #00000080;
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
  animation: fadeIn 300ms;
  z-index: 990;

  @keyframes fadeIn {
    from {
      background: #00000000;
    }
    to {
      background: #00000080;
    }
  }
`;

const StyledSideMenu = styled.div`
  position: relative;
  min-width: 300px;
  height: 100%;
  overflow: auto;
  background: white;
  padding: 24px 20px;
  box-sizing: border-box;
  animation: slideIn 300ms;

  &.left {
    animation-name: slideInLeft;
  }

  & > .title {
    margin: 0 0 20px 0;
    font-weight: 500;
    font-size: 16px;
    line-height: 16px;
  }

  .close-btn {
    position: absolute;
    top: 24px;
    right: 20px;
    line-height: 8px;
    height: 24px;
    width: 24px;
    float: right;
    padding: 0;
    z-index: 100;

    .icon {
      width: 8px;
      height: 8px;
    }
  }

  @media (max-width: ${props => props.theme.breakpoints.sm}) {
    padding: 16px 10px;

    .close-btn {
      top: 16px;
      right: 10px;
    }
  }

  @keyframes slideIn {
    from {
      transform: translateX(100%);
    }
    to {
      transform: translateX(0);
    }
  }

  @keyframes slideInLeft {
    from {
      transform: translateX(-100%);
    }
    to {
      transform: translateX(0);
    }
  }
`;
