import { useEffect, useCallback } from 'react';

const defaultConfig = { condition: true };

const useOutsideClick = (target, callback, { condition } = defaultConfig) => {
  const handleClick = useCallback(
    (e: MouseEvent) => {
      if (target && target.contains && !target.contains(e.target)) callback(e);
    },
    [callback, target]
  );

  useEffect(() => {
    if (!condition) return;

    document.addEventListener('mousedown', handleClick);

    return () => {
      document.removeEventListener('mousedown', handleClick);
    };
  }, [condition, handleClick]);
};

export const useOutsideClickElement = (element: Element | null, callback: any) => {
  const handleClick = e => {
    if (!element?.contains(e.target)) callback(e);
  };

  useEffect(() => {
    document.addEventListener('click', handleClick);

    return () => {
      document.removeEventListener('click', handleClick);
    };
  });
};

export default useOutsideClick;
